<template>
	<article class="lp-container" id="js_lp_container">
		<!-- NOTE: Lp.vueはデザインの型が決まっていなく自由度の高いページ作成で使用 -->
		<!-- CHANGE: v-ifでのコンポーネント切り替えから、computedでコンポーネントを切り替えるよう変更。合わせてDynamicImportにした。 -->
		<component
			:is="computedPage.name"
			:key="computedPage.key"
			@contentHeight="contentHeightController()"
		/>
	</article>
</template>

<script>
export default {
	name: 'Lp',
	components: {
		LpNewyearstamp: () => import(/* webpackChunkName: "LpNewyearstamp" */ '@/components/Organisms/LpNewyearstamp'),
		// LpRainyseason2021: () => import(/* webpackChunkName: "LpRainyseason2021" */ '@/components/Organisms/LpRainyseason2021'),
		LpScratch2021: () => import(/* webpackChunkName: "LpScratch2021" */ '@/components/Organisms/LpScratch2021'),
		LpTyphoon2021: () => import(/* webpackChunkName: "LpTyphoon2021" */ '@/components/Organisms/LpTyphoon2021'),
		LpAutonomicNerves2021: () => import(/* webpackChunkName: "LpAutonomicNerves2021" */ '@/components/Organisms/LpAutonomicNerves2021'),
		LpTemperatureDifference2022: () => import(/* webpackChunkName: "LpTemperatureDifference2022" */ '@/components/Organisms/LpTemperatureDifference2022'),
		LpTemperatureDifference202210: () => import(/* webpackChunkName: "LpTemperatureDifference202210" */ '@/components/Organisms/LpTemperatureDifference202210'),
		LpSpringLethargy2022: () => import(/* webpackChunkName: "LpSpringLethargy2022" */ '@/components/Organisms/LpSpringLethargy2022'),
		LpRainyseason2022: () => import(/* webpackChunkName: "LpRainyseason2022" */ '@/components/Organisms/LpRainyseason2022'),
		LpHeatstroke2022: () => import(/* webpackChunkName: "LpHeatstroke2022" */ '@/components/Organisms/LpHeatstroke2022'),
		LpTyphoon2022: () => import(/* webpackChunkName: "LpTyphoon2022" */ '@/components/Organisms/LpTyphoon2022'),
		LpWinterHealthcare2022Top: () => import(/* webpackChunkName: "LpWinterHealthcare2022Top" */ '@/components/Organisms/LpWinterHealthcare2022Top'),
		LpWinterHealthcare2022Cause: () => import(/* webpackChunkName: "LpWinterHealthcare2022Cause" */ '@/components/Organisms/LpWinterHealthcare2022Cause'),
		LpWinterHealthcare2022Habit: () => import(/* webpackChunkName: "LpWinterHealthcare2022Habit" */ '@/components/Organisms/LpWinterHealthcare2022Habit'),
		LpHeatShock2023: () => import(/* webpackChunkName: "LpHeatShock2023" */ '@/components/Organisms/LpHeatShock2023'),
		LpSpring2023: () => import(/* webpackChunkName: "LpSpring2023" */ '@/components/Organisms/LpSpring2023'),
		LpRainyseason2023: () => import(/* webpackChunkName: "LpRainyseason2023" */ '@/components/Organisms/LpRainyseason2023')
	},
	data () {
		return {
		}
	},
	props: {
		lpType: {
			type: String,
			default: ''
		}
	},
	computed: {
		/**
		 * LPページ（コンポーネント）切り替え処理
		 */
		computedPage () {
			const component = {
				name: '',
				key: ''
			}

			switch (this.lpType) {
				// case 'rainyseason2021':
				// 	component.name = 'LpRainyseason2021'
				// 	break
				case 'lpscratch2021':
					component.name = 'LpScratch2021'
					break
				case 'lptyphoon2021':
					component.name = 'LpTyphoon2021'
					break
				case 'lpautonomicnerves2021':
					component.name = 'LpAutonomicNerves2021'
					break
				case 'lptemperaturedifference2022':
					component.name = 'LpTemperatureDifference2022'
					break
				case 'lpspringlethargy2022':
					component.name = 'LpSpringLethargy2022'
					break
				case 'rainyseason2022':
					component.name = 'LpRainyseason2022'
					break
				case 'heatstroke2022':
					component.name = 'LpHeatstroke2022'
					break
				case 'lptyphoon2022':
					component.name = 'LpTyphoon2022'
					break
				case 'lptemperaturedifference202210':
					component.name = 'LpTemperatureDifference202210'
					break
				case 'lpwinterhealthcare2022top':
					component.name = 'LpWinterHealthcare2022Top'
					break
				case 'lpwinterhealthcare2022cause':
					component.name = 'LpWinterHealthcare2022Cause'
					break
				case 'lpwinterhealthcare2022habit':
					component.name = 'LpWinterHealthcare2022Habit'
					break
				case 'lpheatshock2023':
					component.name = 'LpHeatShock2023'
					break
				case 'lpspring2023':
					component.name = 'LpSpring2023'
					break
				case 'rainyseason2023':
					component.name = 'LpRainyseason2023'
					break
				default:
					component.name = 'LpNewyearstamp'
					break
			}

			// keyの生成
			const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randStr = ''
			for (let i = 0, keyLength = 8; i < keyLength; i++) {
				randStr += chars.charAt(Math.floor(Math.random() * chars.length))
			}
			component.key = `${component.name}_${randStr}`

			return component
		}
	},
	mounted () {
		// console.log('lpType: ', this.lpType)
	},
	beforeDestroy () {
	},
	methods: {
		// コンテンツの高さをemitする処理
		// NOTE: 現在、コンテンツの高さを確保する処理の見直しのため子コンポーネントからemitしないようにしている。未使用だが機能は残しておく。
		contentHeightController () {
			this.$nextTick(() => {
				const domContainer = document.getElementById('js_lp_container')
				if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
			})
		}
	}
}
</script>
